.education {
  color: white;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.school-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 1rem 1rem 1rem;
}
.school-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  background-color: #2a2338;
  border: 1px solid white;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 1rem;
  align-items: center;
}
.school-logo-wrapper{
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  display: inline-block;
  min-width: 200px;
  max-width: 350px;
  justify-self: center;
}
.school-logo{
  width: 100%;
  height: auto;
}
.school-loc{
  font-size: large;
}
.school-attend{
  font-style: italic;
  font-size: small;
}
.school-transcript-wrapper{
  display: flex;
  align-items: center;
}

@media (max-width: 680px){
  .school-container{
    grid-template-columns: 1fr;
  }
}


