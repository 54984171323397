.about {
  color: white;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.about-body{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portrait{
  border-radius: 50%;
  max-width: 200px;
  width: 30%;
  height: auto;
  background-color: #2a2338;
  padding: 5px;
  border: 1px solid white;
  margin-bottom: 3rem;
}

.about-text{
  max-width: 800px;
  margin: 0 1rem 1rem 1rem;
  padding: 10px;
  text-align: center;
  background-color: #2a2338;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid white;
  font-size: medium;
}


@media (max-width: 520px){
  .about-text{
    font-size: small;
  }
}

