.swiper {
  color: white;
  background-color: #2a2338;
  border: 1px solid white;
  border-radius: 10px;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 1000px;
}
.slide {
  padding: 10px 10px 20px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.proj-title-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 2px solid white;
  padding-bottom: 5px;
}
.proj-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0.5rem;
}
.proj-link-card-wrapper{
  display: flex;
  padding: 1rem;
}
.proj-link-card {
  background-color: white;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  position: relative;
}
.proj-link-card:hover {
  box-shadow: 0 0 10px #bf66ff;
  border: 2px solid black;
}
.proj-link-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.proj-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.proj-link-title {
  color: black;
}
.proj-link-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: black;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: xx-large;
}
.proj-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
}
.proj-desc {
  max-width: 100%;
  text-align: center;
}
.list-push {
  padding-left: 20px;
}

#cell2{
  border-left: 1px solid white;
}
#cell3{
  border-top: 1px solid white;
}
#cell4{
  border-top: 1px solid white;
  border-left: 1px solid white;
}

@media (max-width: 700px) {
  .proj-grid-container {
    grid-template-columns: 1fr;
  }

  #cell1{
    border-bottom: 1px solid white;
  }
  #cell2{
    border-bottom: 1px solid white;
    border-left: none;
  }
  #cell3{
    border-bottom: 1px solid white;
    border-top: none;
  }
  #cell4{
    border-bottom: 1px solid white;
    border-top: none;
    border-left: none;
  }
}
