.navbar {
  text-align: center;
  padding-top: 10px;
  pointer-events: all;
}

li.link {
  color: white;
  text-decoration: none;
  display: inline-flex;
  padding-right: 20px;
  font-weight: 700;
  font-size: x-large;
  cursor: pointer;
  list-style: none;
  transition: transform 250ms;
}

li.link:hover {
  transform: translateY(-3px);
  color: #bf66ff;
}

#home {
  text-shadow: 2px 1.5px 2px gray;
}
#about {
  text-shadow: 1px 1.5px 2px gray;
}
#skills {
  text-shadow: 0px 1.5px 2px gray;
}
#education {
  text-shadow: -1px 1.5px 2px gray;
}
#projects {
  text-shadow: -2px 1.5px 2px gray;
}

.menu {
  display: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  background-color: #2a2338;
  border-radius: 50% ;
  border: 1px solid white;
}

.icon-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-title{
  font-size: x-small;
  text-shadow: none;
}

@media (max-width: 520px) {
  .menu {
    display: inline-block;
    z-index: 100;
  }

  .navbar ul {
    position: relative;
    right: 100%;
    transition: 250ms;
    background-color: #2a2338;
    padding: 5px 0px 1px 0px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
  }

  .navbar ul.open {
    right: 0;
    transition: 250ms;
  }
}

