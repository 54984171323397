.home-layer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg {
  background-color: black;
} 
.space_layer {
  background-repeat: repeat-x;
  width: 100%;
  height: 100%;
}
.nav-layer{
  pointer-events: none;
}

#stars-top {
  background-image: url(./assets/space_bg/stars_top.png);
}

#stars-mid {
  background-image: url(./assets/space_bg/stars_mid.png);
}

#stars-bot {
  background-image: url(./assets/space_bg/stars_bot.png);
}

h1 {
  padding-top: 3rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  text-shadow: -2px 1.5px 2px gray;
}

