.projects {
  color: white;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.proj-slide-container{
  padding: 1rem;
  width: 100%;
}

