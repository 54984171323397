.skills {
  color: white;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  border-radius: 3px;
}

.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

.container::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  right: -9px;
  background-color: #2a2338;
  border: 4px solid #bf66ff;
  top: 24px;
  border-radius: 50%;
  z-index: 1;
}

.left {
  left: 0;
}

.right {
  left: 50%;
}

.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 31px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

.right::after {
  left: -9px;
}

.content {
  padding: 10px 15px;
  background-color: #2a2338;
  position: relative;
  border-radius: 10px;
  border: 1px solid white;
}
.content-items-container{
  margin-top: 0.5rem;
  font-size: large;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 0.5rem;
}
.content-item{
  display: inline-flex;
  justify-content: left;
  align-items: center;

}
.skill-icon{
  width: 40px;
  height: auto;
  margin-right: 5px;
}
.skill-icon-title{
  font-size: large;
}

@media screen and (max-width: 830px) {
  .timeline::after {
    left: 31px;
  }

  .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  .left::after, .right::after {
    left: 22px;
  }

  .right {
    left: 0%;
  }
  .skill-icon{
    width: 25px;
    height: auto;
    margin-right: 5px;
  }
  .skill-icon-title{
    font-size: medium;
  }
}